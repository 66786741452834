import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: ${({ displayGrid }) => displayGrid && 'grid'};
    margin: 0 auto;
    max-width: 1024px;
    padding: ${({ lowPadding }) => lowPadding ? '0 5px' : '0 15px'};
    height: inherit;
  
    p {
        @media (max-width: 768px) {
            font-size: clamp(14px, 2.5vw, 19px);
            text-align: left;
        }
    }
`;

const ContainerComponent = ({ children, lowPadding, displayGrid }) => (
  <Container className="container" lowPadding={lowPadding} displayGrid={displayGrid}>
      {children}
  </Container>
);

export default ContainerComponent;